
<template>
    <v-container></v-container>
</template>

<script>
import axios from 'axios'

export default {
    name:'Main',
    data() {
        return {
            
        }
    },
    mounted() {
        this.init();
        
    },
    components: {

    },
    watch: {

    },
    methods: {
        init() {
            // 사용자 정보 호출
            const userInfo = this.$store.getters.getLoginDatas;
            this.getLastMenu();
        },
         getLastMenu() {
            var url = this.$vBACKEND_URL + '/index/main.do?method=getLastMenu';
            var obj = {
                uid_company:this.$store.getters.getUidCompany,
                user_uid:this.$store.getters.getUserUid,
            }
            var params = '';
            for(var key in obj) {
                params += '&' + key + '=' + obj[key]
            }
            url += params;

            fetch(url)
                .then(res => res.clone().json())
                .then(res => {
                    let result = res.datas
                    if(result == null || result.length<1) result = 'main'
                    try {
                        if(result=='LOGIN') result = 'main';
                        this.$router.push(result).catch(()=>{});
                    } catch (error) {
                        console.log('== getLastMenu error', error)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
            }
    }
}
</script>
